import React, { useCallback, useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	LinearProgress
} from "@mui/material";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import FormFieldFileUpload from "../FormFieldFileUpload/FormFieldFileUpload";
import localeContent from "./localeContent";
import { FetchContext } from "../../context/FetchContext";
import { SnackbarContext } from "../../context/SnackbarContext";
import uploadFile from "../../utils/fileUploadUtils";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";
import commonLocaleContent from "../../screens/commonLocaleContent";

const ACCEPTED_TYPES = [".csv"];
const UPLOAD_FOLDER_NAME = "dashboard-uploads";
const ENTITY_PATH = "audience-segment-file";
const SEGMENT_FILE_TYPE = "DASHBOARD_UPLOAD";

const ValidationSchema = Yup.object({
	segmentFile: Yup.mixed().required(commonLocaleContent.REQUIRED_FIELD_WARNING)
});

function appendUuidToFileName(fileName) {
	return `${fileName.replace(/\.[^/.]+$/, "")}-${uuidv4()}${fileName.slice(
		fileName.lastIndexOf(".")
	)}`;
}

function SegmentFileUpload({ setSegmentId, segmentId: parentSegmentId }) {
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(null);
	const { authAxios } = useContext(FetchContext);
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const closeDialog = useCallback(() => {
		setError(null);
		setSegmentId(null); // close the dialog
	}, [setSegmentId]);

	const { execute: saveAudienceSegmentFile } = useResourceAsync(
		"manage/audience-segment-file",
		Operations.CREATE
	);

	const createDatabaseRecord = useCallback(
		async (fileName, internalFileName) => {
			const { error: saveFileError } = await saveAudienceSegmentFile({
				data: {
					displayFileName: fileName,
					internalFileName,
					segmentId: parentSegmentId,
					type: SEGMENT_FILE_TYPE
				}
			});
			if (!isEmpty(saveFileError)) {
				throw new Error();
			}
		},
		[parentSegmentId, saveAudienceSegmentFile]
	);

	const handleUpload = useCallback(
		async formValues => {
			const { segmentFile: file } = formValues;

			const fileName = file.name;
			const internalFileName = `${UPLOAD_FOLDER_NAME}/${appendUuidToFileName(
				fileName
			)}`;
			setUploading(true);
			setError(null);
			try {
				// Upload the file to s3
				await uploadFile(file, internalFileName, ENTITY_PATH, authAxios);
				// Create the database record
				await createDatabaseRecord(fileName, internalFileName);
				triggerNewSnackbarMessage({
					message: localeContent.FILE_UPLOADED_MESSAGE(fileName),
					severity: "success"
				});
				closeDialog();
			} catch (err) {
				setError(localeContent.FILE_UPLOAD_FAILED_MESSAGE(fileName));
			} finally {
				setUploading(false);
			}
		},
		[authAxios, createDatabaseRecord, triggerNewSnackbarMessage, closeDialog]
	);

	const initialFormValues = useMemo(
		() => ({
			segmentFile: null,
			parentSegmentId
		}),
		[parentSegmentId]
	);

	return (
		<Formik
			initialValues={initialFormValues}
			onSubmit={handleUpload}
			validationSchema={ValidationSchema}
			enableReinitialize
		>
			<Dialog
				onClose={closeDialog}
				aria-labelledby="file-upload-dialog-title"
				fullWidth
				maxWidth="sm"
				closeAfterTransition={false}
				disableEscapeKeyDown={uploading}
				open={Boolean(parentSegmentId)}
			>
				<Form noValidate>
					<DialogTitle id="file-upload-dialog-title">
						{localeContent.FILE_UPLOAD_DIALOG_TITLE}
					</DialogTitle>
					<DialogContent>
						<FormFieldFileUpload
							name="segmentFile"
							label={localeContent.FILE_UPLOAD_LABEL}
							fieldTypeOptions={{
								acceptedFileTypes: ACCEPTED_TYPES
							}}
							id="segmentUploadForm"
						/>
						{error && <FormHelperText error>{error}</FormHelperText>}
						{uploading && <LinearProgress />}
					</DialogContent>
					<DialogActions>
						<Button onClick={closeDialog} color="primary" disabled={uploading}>
							{commonLocaleContent.CANCEL}
						</Button>
						<Button disabled={uploading} color="primary" type="submit">
							{uploading
								? localeContent.UPLOADING_BUTTON_LABEL
								: localeContent.UPLOAD_BUTTON_LABEL}
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</Formik>
	);
}

SegmentFileUpload.propTypes = {
	setSegmentId: PropTypes.func.isRequired,
	segmentId: PropTypes.string
};

SegmentFileUpload.defaultProps = {
	segmentId: null
};

export default SegmentFileUpload;
