import React from "react";
import * as Yup from "yup";
import { format } from "date-fns";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import { SERVER_DATE_FORMAT } from "../../components/FormFieldDateTimePicker/FormFieldDateTimePicker";
import commonFieldOptions from "../commonFieldOptions";
import validators from "../../utils/validators";
import { BREADCRUMBS_ENUM } from "../../config/constants";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";

const HOUSE_AD_KEY = "houseAd";
export const FREQUENCY_CAPPING_PERIOD_OPTIONS = [
	{
		value: "60",
		label: localeContent.FREQUENCY_CAPPING_PERIOD_OPTION_PER_MINUTE
	},
	{
		value: "3600",
		label: localeContent.FREQUENCY_CAPPING_PERIOD_OPTION_PER_HOUR
	},
	{
		value: "86400",
		label: localeContent.FREQUENCY_CAPPING_PERIOD_OPTION_PER_DAY
	},
	{
		value: "604800",
		label: localeContent.FREQUENCY_CAPPING_PERIOD_OPTION_PER_WEEK
	}
];
export const DEFAULT_FREQUENCY_CAPPING_PERIOD = 86400;

const breadcrumbsConfig = [
	BREADCRUMBS_ENUM.ADVERTISER,
	BREADCRUMBS_ENUM.INSERTION_ORDER,
	BREADCRUMBS_ENUM.NAME
];
const TABS_CONFIG = [
	{
		label: commonLocaleContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "INSERTION_ORDER_SETTINGS",
				hideOnEdit: true,
				fields: [
					{
						name: "insertionOrderId",
						label: localeContent.INSERTION_ORDER_ID_LABEL,
						tooltip: localeContent.INSERTION_ORDER_ID_TOOLTIP,
						isRequired: true,
						disableOnEdit: true,
						gridConfig: { md: 8 },
						fieldType: FieldTypes.COMBO_BOX,
						optionsConfig: {
							requestEndpoint: "manage/search/insertion-orders"
						}
					}
				]
			},
			{
				key: "BASIC_SETTINGS",
				renderIf: formValues => formValues.insertionOrderId,
				fields: [
					{
						name: "name",
						label: localeContent.CAMPAIGN_NAME_LABEL,
						isRequired: true,
						gridConfig: { md: 8 },
						validationSchema: Yup.string().max(
							255,
							commonLocaleContent.MAX_STRING_LENGTH_EXCEEDED
						)
					},
					{
						name: "status",
						label: commonLocaleContent.FIELD_LABEL_STATUS,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "running",
									label: commonLocaleContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "stopped",
									label: commonLocaleContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						name: HOUSE_AD_KEY,
						label: localeContent.HOUSE_AD_LABEL,
						tooltip: localeContent.HOUSE_AD_LABEL_TOOLTIP,
						gridConfig: { md: 2 },
						fieldType: FieldTypes.CHECKBOX_SINGLE,
						defaultValue: false,
						disableOnEdit: true
					},
					{
						name: "cpm",
						label: localeContent.CPM_LABEL,
						tooltip: localeContent.CPM_TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.CURRENCY,
						isRequired: true,
						disableIf: formValues => formValues.houseAd,
						validationSchema: Yup.number().max(
							99999.99,
							commonLocaleContent.MAX_NUMBER_VALUE_EXCEEDED
						),
						dependentFieldValueSetter: (formValues, setFieldValue) => {
							if (formValues[HOUSE_AD_KEY]) {
								setFieldValue(0);
							}
						},
						defaultValue: 0
					},
					{
						name: "opportunityExposure",
						label: localeContent.OPPORTUNITY_EXPOSURE_LABEL,
						tooltip: localeContent.OPPORTUNITY_EXPOSURE_TOOLTIP,
						gridConfig: { md: 4 },
						isRequired: false,
						fieldTypeOptions: {
							endAdornmentText: "%",
							permissionRequired: "EDIT_OPPORTUNITY_EXPOSURE"
						},
						validationSchema: Yup.number()
							.min(
								0.0001,
								commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_BETWEEN_0001_100
							)
							.max(
								100,
								commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_BETWEEN_0001_100
							),
						fieldType: FieldTypes.PERCENT,
						defaultValue: "",
						disableOnEdit: true
					},
					{
						name: "allowRevenueLoss",
						label: localeContent.ALLOW_REVENUE_LOSS_LABEL,
						tooltip: localeContent.ALLOW_REVENUE_LOSS_TOOLTIP,
						permissionRequired: "EDIT_ALLOW_REVENUE_LOSS_CAMPAIGN_FORM",
						gridConfig: { md: 4 },
						isRequired: false,
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					}
				]
			},
			{
				key: "FLIGHTING_SETTINGS",
				renderIf: formValues => formValues.insertionOrderId,
				fields: [
					{
						name: "startDate",
						label: localeContent.START_DATE_LABEL,
						tooltip: localeContent.START_DATE_TOOLTIP,
						gridConfig: { md: 5 },
						fieldType: FieldTypes.DATE_TIME_PICKER,
						isRequired: true,
						fieldTypeOptions: {
							showTodayButton: true
						},
						defaultValue: format(new Date(), SERVER_DATE_FORMAT)
					},
					{
						name: "endDate",
						label: localeContent.END_DATE_LABEL,
						tooltip: localeContent.END_DATE_TOOLTIP,
						gridConfig: { md: 5 },
						fieldType: FieldTypes.DATE_TIME_PICKER,
						fieldTypeOptions: {
							clearable: true
						},
						defaultValue: null,
						validationSchema: Yup.date().when(["goal", "pacingStrategy"], {
							is: (goal, pacingStrategy) =>
								(goal === "spend" || goal === "impressions") &&
								pacingStrategy === "even",
							then: Yup.date().required(
								commonLocaleContent.REQUIRED_FIELD_WARNING
							)
						})
					}
				]
			},
			{
				key: "DELIVERY_SETTINGS",
				renderIf: formValues => formValues.insertionOrderId,
				fields: [
					{
						name: "goal",
						label: localeContent.GOAL_TYPE_LABEL,
						tooltip: localeContent.GOAL_TYPE_TOOLTIP,
						gridConfig: { md: 5 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "impressions",
									label: localeContent.GOAL_TYPE_LABEL_IMPRESSION
								},
								{
									value: "spend",
									label: localeContent.GOAL_TYPE_LABEL_SPEND,
									disableIf: formValues =>
										formValues.houseAd && formValues.houseAd === true
								},
								{ value: "open", label: localeContent.GOAL_TYPE_LABEL_OPEN }
							]
						},
						dependentFieldValueSetter: (formValues, setFieldValue) => {
							if (formValues[HOUSE_AD_KEY] && formValues.goal === "spend") {
								setFieldValue("impressions");
							}
						},
						defaultValue: "impressions"
					},
					{
						name: "impressionsTarget",
						label: localeContent.IMPRESSION_GOAL_LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						renderIf: formValues => formValues.goal === "impressions",
						validationSchema: Yup.number().when("goal", {
							is: goal => goal === "impressions",
							then: Yup.number().required(
								`Required when ${localeContent.GOAL_TYPE_LABEL} is ${localeContent.GOAL_TYPE_LABEL_IMPRESSION}`
							)
						})
					},
					{
						name: "spendTarget",
						label: localeContent.SPEND_GOAL_LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.CURRENCY,
						renderIf: formValues => formValues.goal === "spend",
						validationSchema: Yup.number().when("goal", {
							is: goal => goal === "spend",
							then: Yup.number().required(
								`Required when ${localeContent.GOAL_TYPE_LABEL} is ${localeContent.GOAL_TYPE_LABEL_SPEND}`
							)
						})
					},
					{
						name: "pacingStrategy",
						label: localeContent.PACING_STRATEGY_LABEL,
						tooltip: localeContent.PACING_STRATEGY_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.RADIO,
						renderIf: formValues =>
							["impressions", "spend"].some(goal => goal === formValues.goal),
						optionsConfig: {
							options: [
								{
									value: "even",
									label: localeContent.PACING_STRATEGY_LABEL_EVEN
								},
								{
									value: "asap",
									label: localeContent.PACING_STRATEGY_LABEL_ASAP
								}
							]
						},
						defaultValue: "even"
					},
					{
						name: "enableFrequencyCapping",
						label: localeContent.ENABLE_FREQUENCY_CAPPING_LABEL,
						tooltip: localeContent.ENABLE_FREQUENCY_CAPPING_TOOLTIP,
						getGridConfig: formValues => ({
							md: 4,
							...(formValues.goal === "open" ? { offset: { md: 7 } } : {})
						}),
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "frequencyCappingAmount",
						label: localeContent.FREQUENCY_CAPPING_AMOUNT_LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						renderIf: formValues => formValues.enableFrequencyCapping,
						validationSchema: Yup.number().when("enableFrequencyCapping", {
							is: enableFrequencyCapping => enableFrequencyCapping === true,
							then: Yup.number().required(
								localeContent.FREQUENCY_CAPPING_AMOUNT_REQUIRED
							)
						})
					},
					{
						name: "frequencyCappingPeriod",
						label: localeContent.FREQUENCY_CAPPING_PERIOD_LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							// TODO: this is going to change for use of a an enum instead of seconds, this is the case related to this https://beezag.jira.com/browse/CP-2115
							options: FREQUENCY_CAPPING_PERIOD_OPTIONS
						},
						defaultValue: DEFAULT_FREQUENCY_CAPPING_PERIOD,
						renderIf: formValues => formValues.enableFrequencyCapping
					}
				]
			},
			{
				key: "PRIORITY_SETTINGS",
				renderIf: formValues => formValues.insertionOrderId,
				fields: [
					{
						name: "priority",
						label: localeContent.CAMPAIGN_PRIORITY_LABEL,
						tooltip: localeContent.CAMPAIGN_PRIORITY_TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [...commonFieldOptions.PRIORITY]
						},
						defaultValue: ""
					},
					{
						name: "weight",
						label: localeContent.CAMPAIGN_WEIGHT_LABEL,
						tooltip: localeContent.CAMPAIGN_WEIGHT_TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [...commonFieldOptions.WEIGHT]
						},
						defaultValue: ""
					}
				]
			}
		]
	},
	{
		label: commonLocaleContent.TAB_LABEL_QUALITY,
		key: "QUALITY",
		disableIf: formValues => !formValues.insertionOrderId,
		fieldsConfig: [
			{
				key: "MEDIA_SETTINGS",
				fields: [
					{
						name: "singleAdMediaResponse",
						label: localeContent.SINGLE_AD_MEDIA_RESPONSE_LABEL,
						fieldType: FieldTypes.CHECKBOX_SINGLE,
						defaultValue: false
					}
				]
			},
			{
				key: "GEO_TARGETING_SETTINGS",
				fields: [
					{
						name: "geoTargetingConfig",
						fieldType: FieldTypes.GEO_TARGETING,
						useInheritedValues: true
					}
				]
			},
			{
				key: "DEVICE_TARGETING_SETTINGS",
				fields: [
					{
						name: "deviceTargeting",
						fieldType: FieldTypes.DEVICE_TARGETING,
						useInheritedValues: true,
						defaultValue: { devices: [] }
					}
				]
			},
			{
				key: "SIZE_TARGETING_SETTINGS",
				fields: [
					{
						name: "sizeTargeting",
						fieldType: FieldTypes.SIZE_TARGETING
					}
				]
			},
			{
				key: "LIST_TARGETING_SETTINGS",
				fields: [
					{
						name: "blacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						useInheritedValues: true,
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appNameBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_APP_NAME_LABEL,
							validatorFunction: validators.validateUTF8
						},
						useInheritedValues: true,
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appBundleIdBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel:
								localeContent.TARGETING_TYPE_APP_BUNDLE_IDS_LABEL,
							validatorFunction: validators.validateUTF8
						},
						useInheritedValues: true,
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					}
				]
			},
			{
				key: "DAY_PARTING_SETTINGS",
				fields: [
					{
						name: "dayParting",
						fieldType: FieldTypes.DAY_PARTING
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_MEDIA,
		key: "MEDIA",
		disableOnCreate: true,
		childResourceConfig: {
			columnsConfig: [
				{
					name: commonLocaleContent.COLUMN_HEADER_STATUS,
					key: COLUMN_KEY_STATUS,
					cellDataType: "upperCase"
				},
				{
					name: commonLocaleContent.COLUMN_HEADER_CPM,
					key: "cpm",
					cellDataType: "currency"
				}
			],
			resourceEndpoint: "manage/media",
			resourceRoute: "media",
			resourceName: commonLocaleContent.MEDIA,
			getRequestParams: campaignId => ({
				campaignId
			})
		}
	}
];

const viewReportConfig = {
	permissionsRequired: ["VIEW_REPORT_BUTTON", "VIEW_CAMPAIGN_REPORT"],
	reportType: "campaign",
	filterKeyValuesGetter: context => {
		return {
			campaign: context.initData.guid
		};
	}
};

function CampaignResource() {
	const cloneDayParting = data => {
		const dayParting = data ? data.dayParting : null;
		return dayParting ? { ...dayParting, id: "" } : null;
	};

	return (
		<CrudForm
			resourceEndpoint="manage/campaigns"
			resourceRoute="campaigns"
			parentIdField="insertionOrderId"
			parentStatusKey="ioStatus"
			breadcrumbsConfig={breadcrumbsConfig}
			resourceString={commonLocaleContent.CAMPAIGN}
			tabsConfig={TABS_CONFIG}
			mapCloneDataOverride={data => {
				return {
					...data,
					name: "",
					dayParting: cloneDayParting(data)
				};
			}}
			cloneUrlWithId
			viewReportConfig={viewReportConfig}
		/>
	);
}

export default CampaignResource;
