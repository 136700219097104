export default {
	TAB_LABEL_MEDIA: "MEDIA",
	INSERTION_ORDER_ID_LABEL: "Insertion Name",
	INSERTION_ORDER_ID_TOOLTIP:
		"Select the Insertion which this Campaign belongs to.",
	HOUSE_AD_LABEL: "House Ad",
	HOUSE_AD_LABEL_TOOLTIP: "Enable for house ads that will not earn revenue",
	OPPORTUNITY_EXPOSURE_LABEL: "Opportunity Exposure %",
	OPPORTUNITY_EXPOSURE_TOOLTIP:
		"The percentage of ad responses that the campaign will be returned in as compared to the total amount possible.",
	ALLOW_REVENUE_LOSS_LABEL: "Allow Revenue Loss",
	ALLOW_REVENUE_LOSS_TOOLTIP:
		"If enabled, the Media associated with this Campaign will be allowed to run on Placements where the floor is greater than the Media CPM.  As a result, delivery of this Campaign could result in a loss of revenue.",
	CAMPAIGN_NAME_LABEL: "Campaign Name",
	CPM_LABEL: "CPM",
	CPM_TOOLTIP:
		"Used to calculate how much revenue has been earned for a given Campaign.",
	START_DATE_LABEL: "Start Date",
	START_DATE_TOOLTIP: "Date and Time at which this campaign will begin",
	END_DATE_LABEL: "End Date",
	END_DATE_TOOLTIP:
		"Date and Time at which this campaign will end.  If an End Date is not supplied then the campaign will run indefinitely.",
	GOAL_TYPE_LABEL: "Goal Type",
	GOAL_TYPE_TOOLTIP:
		"Select Impression or Spend and enter the corresponding goal to pace the campaign across the given flight dates. If Open is selected a goal is not required and the campaign will run without pacing.",
	GOAL_TYPE_LABEL_OPEN: "Open",
	GOAL_TYPE_LABEL_SPEND: "Spend",
	GOAL_TYPE_LABEL_IMPRESSION: "Impression",
	IMPRESSION_GOAL_LABEL: "Impression Goal",
	SPEND_GOAL_LABEL: "Spend Goal",
	PACING_STRATEGY_LABEL: "Pacing Type",
	PACING_STRATEGY_TOOLTIP:
		"Select Even to pace the Campaign Goal evenly across the given flight dates. Select ASAP to deliver the Campaign Goal as quickly as possible until either the goal is met or the end date is reached.",
	PACING_STRATEGY_LABEL_EVEN: "Even",
	PACING_STRATEGY_LABEL_ASAP: "ASAP",
	ENABLE_FREQUENCY_CAPPING_LABEL: "Frequency Capping",
	ENABLE_FREQUENCY_CAPPING_TOOLTIP:
		"If enabled, enter a number into the displayed text field to limit the number of times a user can view this campaign over the specified timeframe. If disabled there are no restrictions on the number of views per user.",
	FREQUENCY_CAPPING_AMOUNT_LABEL: "Impressions Per User",
	FREQUENCY_CAPPING_PERIOD_LABEL: "Timeframe",
	FREQUENCY_CAPPING_PERIOD_OPTION_PER_MINUTE: "Per Minute",
	FREQUENCY_CAPPING_PERIOD_OPTION_PER_HOUR: "Per Hour",
	FREQUENCY_CAPPING_PERIOD_OPTION_PER_DAY: "Per Day",
	FREQUENCY_CAPPING_PERIOD_OPTION_PER_WEEK: "Per Week",
	CAMPAIGN_PRIORITY_LABEL: "Campaign Priority",
	CAMPAIGN_PRIORITY_TOOLTIP:
		"Priority is used to specify the order in which Campaigns should be served. A Priority of 1 will serve before all other priority levels.",
	CAMPAIGN_WEIGHT_LABEL: "Campaign Weight",
	CAMPAIGN_WEIGHT_TOOLTIP:
		"Weighting is used to differentiate between Priorities of the same level. The first option to serve will be Priority 1 with a Weight of 10. The last option to serve will be a Priority of 10 with a Weight of 1.",
	SINGLE_AD_MEDIA_RESPONSE_LABEL: "Return One Media in Ad Response",
	TARGETING_TYPE_DOMAIN_LABEL: "Domain",
	TARGETING_TYPE_APP_NAME_LABEL: "App Name",
	TARGETING_TYPE_APP_BUNDLE_IDS_LABEL: "App Bundle ID",
	FREQUENCY_CAPPING_AMOUNT_REQUIRED:
		"Required when frequency capping is enabled"
};
